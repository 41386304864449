



































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import Avatar from '@/modules/community/common/components/avatar.vue'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'

@Observer
@Component({
  components: {
    Avatar,
  },
})
export default class MostActivePost extends Vue {
  @Inject({}) vm!: CommunityDetailViewModel
}
